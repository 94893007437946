import React, { useState } from "react";
import { connect } from "react-redux";
import { Button } from "expano-components";
import { updateChannelProductGroup } from "actions/product_group/channel_product_group";

import { ReactComponent as BulletPointIcon } from "icons/bullet_point.svg";
import { ReactComponent as ArrowUpIcon } from "icons/arrow_up.svg";
import { ReactComponent as ArrowDownIcon } from "icons/arrow_down.svg";

const generateInitialState = (data) => {
  const {
    bullet_point1,
    bullet_point2,
    bullet_point3,
    bullet_point4,
    bullet_point5,
  } = data;
  const arr = [
    bullet_point1,
    bullet_point2,
    bullet_point3,
    bullet_point4,
    bullet_point5,
  ];
  const arr_filtered = arr.filter(Boolean);
  if (arr_filtered?.length > 0) {
    return arr_filtered.map((value, index) => ({
      id: index + 1,
      value,
    }));
  }
  return [{ id: 1, value: "" }];
};

const BulletPointsForm = ({
  initialState,
  channel_product_group_id,
  updateChannelProductGroup,
}) => {
  const [bullet_points, setBulletPoint] = useState(
    initialState
  );
  const arrayMove = (old_index, new_index) => {
    const arr = [...bullet_points];
    while (old_index < 0) {
      old_index += arr.length;
    }
    while (new_index < 0) {
      new_index += arr.length;
    }
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    setBulletPoint(arr);
  };

  const onSubmit = async () => {
    const data = {};
    Array(5)
      .fill(null)
      .forEach((item, index) => {
        data[`bullet_point${index + 1}`] =
          bullet_points?.[index]?.value || null;
      });
    await updateChannelProductGroup(
      channel_product_group_id,
      data
    );
  };

  return (
    <div className="dashboard">
      <header className="dashboard__header dashboard__header--edit">
        <div className="icon__container">
          <BulletPointIcon />
        </div>
        <h2 className="dashboard__title heading">
          Bullet points
        </h2>
        {bullet_points?.length < 5 && (
          <button
            className="btn btn-sm btn-blue"
            onClick={() => {
              const points = [...bullet_points];
              setBulletPoint([
                ...points,
                { id: points.length + 1, value: "" },
              ]);
            }}>
            <BulletPointIcon />
            <span>Dodaj bullet point</span>
          </button>
        )}
      </header>
      <form onSubmit={onSubmit}>
        <div className="dashboard__form__submit">
          {bullet_points.map(({ id, value }, index) => (
            <div className="bullet-point-group" key={index}>
              <div className="form-group">
                <div className="textarea-wrapper">
                  <div className="textarea-group">
                    <textarea
                      id={`bullet-point_${id}`}
                      name={`bullet-point_${id}`}
                      style={{ minHeight: 100 }}
                      value={value}
                      onChange={({ target: { value } }) => {
                        const data = [...bullet_points];
                        if (value?.length > 250) {
                          value = value.slice(0, 250);
                        }
                        data[index].value = value;
                        setBulletPoint(data);
                      }}
                    />
                  </div>
                </div>
                <p className="mark-counter">
                  {value?.length}/250 znaków
                </p>
              </div>
              <div className="order-buttons">
                <button
                  type="button"
                  className="order-button up"
                  disabled={
                    bullet_points.length === 1 ||
                    index === 0
                  }
                  onClick={() =>
                    arrayMove(index, index - 1)
                  }>
                  <ArrowUpIcon />
                </button>
                <button
                  type="button"
                  className="order-button down"
                  disabled={
                    bullet_points.length === 1 ||
                    index === bullet_points.length - 1
                  }
                  onClick={() =>
                    arrayMove(index, index + 1)
                  }>
                  <ArrowDownIcon />
                </button>
              </div>
              {bullet_points.length > 1 && (
                <Button
                  type="delete-bin"
                  onClick={() => {
                    let data = [...bullet_points];
                    data = data.filter(
                      (item, i) => index !== i
                    );
                    data = data.map((item, i) => ({
                      ...item,
                      id: i + 1,
                    }));
                    setBulletPoint(data);
                  }}
                />
              )}
            </div>
          ))}
        </div>
        <div className="dashboard__form__footer">
          <Button
            type="save"
            text="Zapisz"
            onClick={onSubmit}
          />
        </div>
      </form>
    </div>
  );
};

export default connect(
  ({ channel_product_group: { data } }) => ({
    initialState: generateInitialState(data),
    channel_product_group_id: data.id,
  }),
  (dispatch) => ({
    updateChannelProductGroup: (id, data) =>
      dispatch(updateChannelProductGroup(id, data)),
  })
)(BulletPointsForm);
