import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Main, PageLoader } from "components";
import {
  Price,
  Specification,
  ProductImages,
  Alias,
  ExportPanel,
  ProductCard,
  PlatformTaxons,
  CountryKeywords,
} from "./panels";
import {
  getProductGroup,
  resetProductGroup,
} from "actions/product_group";

import { ReactComponent as ProductIcon } from "icons/product.svg";

const tabs = [
  {
    id: "specification",
    name: "Specyfikacja",
  },
  {
    id: "price",
    name: "Ceny",
  },
  {
    id: "product_cards",
    name: "Karty produktowe",
  },
  {
    id: "images",
    name: "Baza zdjęć",
  },
  {
    id: "platform_taxons",
    name: "Kategorie",
  },
  {
    id: "aliases",
    name: "Aliasy",
  },
  {
    id: "statuses",
    name: "Statusy",
  },
  {
    id: "country_keywords",
    name: "Słowa kluczowe",
  },
];

const Panels = ({ tab, product_group_id }) => {
  switch (tab) {
    case "specification":
      return <Specification />;
    case "price":
      return <Price />;
    case "images":
      return <ProductImages />;
    case "aliases":
      return <Alias />;
    case "product_cards":
      return <ProductCard />;
    case "statuses":
      return <ExportPanel />;
    case "platform_taxons":
      return <PlatformTaxons />;
    case "country_keywords":
      return <CountryKeywords />;
    default: {
      return (
        <Redirect
          to={`/product_groups/${product_group_id}/specification`}
        />
      );
    }
  }
};

const EditProductGroup = ({
  product_group,
  history,
  match: {
    params: { product_group_id, tab },
  },
  getProductGroup,
  resetProductGroup,
}) => {
  useEffect(() => {
    getProductGroup(product_group_id);
    return () => resetProductGroup();
  }, [product_group_id]);

  const active_tab = tabs.find(({ id }) => id === tab);

  if (
    ["invalid", "loading", "failure"].includes(
      product_group.status
    )
  ) {
    return <PageLoader />;
  }

  const { data } = product_group;

  return (
    <Main
      breadcrumbs={[
        { name: "Produkty", path: "/products" },
        {
          name: active_tab
            ? active_tab.name
            : "Specyfikacja",
        },
      ]}
      page={{
        name: `${data?.name} [${data?.producer?.name ||
          "-"}]`,
        icon: <ProductIcon />,
        breadcrumbs: [
          {
            name: "Produkty",
            path: "/products",
          },
          { name: data?.sku },
        ],
      }}>
      <ul className="main-tabs">
        {tabs.map(({ id, name }) => (
          <li
            key={id}
            className={`tab dashboard-tab-text ${
              id === tab ? "active" : ""
            }`}
            onClick={() =>
              history.push(
                `/product_groups/${product_group_id}/${id}`
              )
            }>
            {name}
          </li>
        ))}
      </ul>
      <Panels {...{ product_group_id, tab }} />
    </Main>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getProductGroup: (id) => dispatch(getProductGroup(id)),
  resetProductGroup: () => dispatch(resetProductGroup()),
});

export default connect(
  ({ product_group }) => ({
    product_group,
  }),
  mapDispatchToProps
)(EditProductGroup);
