import { toastr } from "react-redux-toastr";
import { client } from "utils/api";

export const resetProductGroup = () => async (dispatch) =>
  dispatch({ type: "PRODUCT_GROUP_RESET" });

export const getProductGroup = (id) => async (dispatch, getState) => {
  try {
    const {
      product_group: { status },
    } = getState();

    if (status === "invalid") {
      dispatch({ type: "PRODUCT_GROUP_LOADING" });
    }

    const { data } = await client.get(`/product_groups/${id}?context=ui_show`);
    dispatch({
      type: "PRODUCT_GROUP_SUCCESS",
      data,
    });
  } catch (error) {
    dispatch({ type: "PRODUCT_GROUP_FAILURE" });
    toastr.error("Błąd", "Wystąpił błąd podzczas pobierania");
  }
};

export const updateProductGroup = (id, values) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      await client.put(`/product_groups/${id}`, values);
      await dispatch(getProductGroup(id));
      toastr.success("Sukces", "Produkt grupowy został zaktualizowany");
      resolve();
    } catch (error) {
      reject(error);
      toastr.error("Błąd", "Wystąpił błąd podzczas aktualiozwania");
    }
  });

export const assignPricesFromProductGroup =
  ({ product_group_id, cloned_product_group_id }) =>
  async (dispatch) => {
    try {
      await client.post(
        `/product_groups/${product_group_id}/assign_prices_from_product_group`,
        {
          cloned_product_group_id,
        }
      );
      await dispatch(getProductGroup(product_group_id));
      toastr.success("Sukces", "Import przebiegł pomyślnie");
    } catch (error) {
      console.log(error);
      toastr.error("Błąd", "Wystąpił błąd podzczas importowania");
      throw error;
    }
  };
