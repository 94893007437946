import React from "react";
import { Button, Input } from "expano-components";
import { Field, reduxForm } from "redux-form";
import { bulkUpdateProducts } from "actions/product_group/product";
import { connect } from "react-redux";

const InputField = (props) => (
  <Input {...props} input={{ ...props.input, step: "0.01" }} />
);

const getMarkupPercent = (markup) => {
  if (!markup) {
    return "-";
  }

  return [parseFloat(markup * 100).toFixed(2), "%"].join("");
};

const ProductsPricesForm = ({
  current_user,
  products,
  pristine,
  submitting,
  handleSubmit,
  bulkUpdateProducts,
}) => {
  const onSubmit = ({ products }) => bulkUpdateProducts(products);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {products.map((product) => (
        <div className="p-20" key={product.id}>
          {current_user.permission_display_purchase_price && (
            <p className="text-muted">
              Cena zakupu netto: {product.purchase_price_netto} zł
            </p>
          )}
          <p className="m-b-10">
            {product?.sku} - {product?.name}{" "}
          </p>
          <div className="row wrap">
            <div className="box-2">
              <Field
                postfix="PLN"
                type="number"
                name={`products[${product.id}][sales_price_pln]`}
                component={InputField}
              />
              {current_user.permission_display_purchase_price && (
                <p className="text-muted">
                  Narzut: {getMarkupPercent(product?.sales_markup_pln)}
                </p>
              )}
            </div>
            <div className="box-2">
              <Field
                postfix="EUR"
                type="number"
                name={`products[${product.id}][sales_price_eur]`}
                component={InputField}
              />
              {current_user.permission_display_purchase_price && (
                <p className="text-muted">
                  Narzut: {getMarkupPercent(product?.sales_markup_eur)}
                </p>
              )}
            </div>
            <div className="box-2">
              <Field
                postfix="CZK"
                type="number"
                name={`products[${product.id}][sales_price_czk]`}
                component={InputField}
              />
              {current_user.permission_display_purchase_price && (
                <p className="text-muted">
                  Narzut: {getMarkupPercent(product?.sales_markup_czk)}
                </p>
              )}
            </div>
            <div className="box-2">
              <Field
                postfix="SEK"
                type="number"
                name={`products[${product.id}][sales_price_sek]`}
                component={InputField}
              />
              {current_user.permission_display_purchase_price && (
                <p className="text-muted">
                  Narzut: {getMarkupPercent(product?.sales_markup_sek)}
                </p>
              )}
            </div>
            <div className="box-2">
              <Field
                postfix="GBP"
                type="number"
                name={`products[${product.id}][sales_price_gbp]`}
                component={InputField}
              />
              {current_user.permission_display_purchase_price && (
                <p className="text-muted">
                  Narzut: {getMarkupPercent(product?.sales_markup_gbp)}
                </p>
              )}
            </div>
          </div>
        </div>
      ))}

      <div className="dashboard__form__footer" style={{ borderTop: "none" }}>
        <Button
          type="save"
          disabled={pristine || submitting}
          text="Zapisz"
          onClick={handleSubmit(onSubmit)}
        />
      </div>
    </form>
  );
};

const getProductsInitialValues = (data) => {
  const products = {};

  data.forEach(
    ({
      id,
      sales_price_pln,
      sales_price_eur,
      sales_price_czk,
      sales_price_sek,
      sales_price_gbp,
    }) => {
      products[id] = {
        sales_price_pln,
        sales_price_eur,
        sales_price_czk,
        sales_price_sek,
        sales_price_gbp,
      };
    }
  );

  return { products };
};

export default connect(
  ({
    current_user,
    product_group: {
      data: { products },
    },
  }) => ({
    current_user,
    products,
    initialValues: getProductsInitialValues(products),
  }),
  (dispatch) => ({
    bulkUpdateProducts: (products) => dispatch(bulkUpdateProducts(products)),
  })
)(
  reduxForm({
    form: "products_price_form",
    enableReinitialize: true,
  })(ProductsPricesForm)
);
