import React, { useState } from "react";
import moment from "moment";
import classNames from "classnames";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { refreshPrice } from "actions/product_group/product";
import useQueryHook from "utils/useQueryHook";
import { stringifyQuery } from "utils/api";
import { PanelLoader, Statuses } from "components";
import ProductPricesForm from "./components/ProductPricesForm";
import ChannelPricesPanel from "./components/ChannelPricesPanel";

import { ReactComponent as PriceLabelIcon } from "icons/price-label.svg";
import { ReactComponent as ClockIcon } from "icons/clock.svg";

const query = {
  context: "ui_price_product",
  per_page: 50,
};

const ProductPricesPanel = ({
  current_user,
  product_group,
  refreshPrice,
  match: {
    params: { type_id },
  },
}) => {
  const [is_refreshing_price, setRefreshingPrice] = useState(false);

  const {
    is_loading,
    is_fetching,
    data: { data },
    refetch,
  } = useQueryHook({
    query_keys: [`prices_${type_id}`],
    path: `/channel_products?${stringifyQuery({
      ...query,
      "q[product_id_eq]": type_id,
    })}`,
    enabled: !!type_id,
  });

  const handleRefreshPrice = async () => {
    try {
      setRefreshingPrice(true);
      await refreshPrice(product.id);
    } catch (error) {
      console.log(error);
    } finally {
      setRefreshingPrice(false);
    }
  };

  const product = product_group.products.find(
    ({ id }) => id === Number(type_id)
  );

  if (!product) {
    return null;
  }

  if (is_loading) {
    return <PanelLoader />;
  }

  const channel_products = data.sort(
    (item, next) => item.channel?.id - next.channel?.id
  );

  const hide_prices =
    !product.purchase_price_netto ||
    parseFloat(product?.purchase_price_netto) === 0;

  return (
    <div
      className={classNames("box-full", {
        fetching: is_fetching,
      })}
    >
      <div className="dashboard" style={{ marginBottom: 0 }}>
        <header className="dashboard__header dashboard__header--edit">
          <div className="icon__container">
            <PriceLabelIcon />
          </div>
          <h2 className="dashboard__title heading">Ceny produktu</h2>
          {hide_prices ? (
            <Statuses
              status="danger"
              text="Brak ceny zakupu! Aby ustalić cenę sprzedaży
            musisz najpierw ustalić cenę zakupu."
            />
          ) : (
            <>
              {current_user.permission_display_purchase_price ? (
                <p className="platform-name">
                  Cena zakupu netto: {product.purchase_price_netto} zł, data
                  aktualizacji:{" "}
                  {product.updated_purchase_price_netto_at
                    ? moment(product.updated_purchase_price_netto_at).format(
                        "DD-MM-YYYY HH:mm"
                      )
                    : ""}{" "}
                  <button
                    type="button"
                    disabled={is_refreshing_price}
                    onClick={handleRefreshPrice}
                  >
                    <ClockIcon />
                  </button>
                </p>
              ) : null}
            </>
          )}
        </header>
        {!hide_prices ? (
          <ProductPricesForm
            product={product}
            refetch={refetch}
            initialValues={{
              sales_price_pln: product.sales_price_pln,
              sales_price_eur: product.sales_price_eur,
              sales_price_czk: product.sales_price_czk,
              sales_price_sek: product.sales_price_sek,
              sales_price_gbp: product.sales_price_gbp,
            }}
          />
        ) : null}
      </div>
      <div className="dashboard">
        <header className="dashboard__header dashboard__header--edit">
          <div className="icon__container">
            <PriceLabelIcon />
          </div>
          <h2 className="dashboard__title heading">
            Ceny produktu na kanałach
          </h2>
        </header>
        {!hide_prices ? (
          <>
            {channel_products?.length > 0 ? (
              <ChannelPricesPanel data={channel_products} />
            ) : (
              <div className="dashboard__body">
                <p>Brak kanałów</p>
              </div>
            )}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default connect(
  ({ current_user, product_group: { data: product_group } }) => ({
    current_user,
    product_group,
  }),
  (dispatch) => ({
    refreshPrice: (product_id) => dispatch(refreshPrice(product_id)),
  })
)(withRouter(ProductPricesPanel));
